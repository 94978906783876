import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { FormProvider, useForm } from "react-hook-form"

import AssetsDialog from '../../components/assets/dialog'
import AutoSave from '../../components/autosave'
import { useTitle } from '../../components/browser'
import Errors from '../../components/errors'
import Boolean from '../../components/form/boolean'
import DateInput from '../../components/form/dateinput'
import Fieldset from '../../components/form/fieldset'
import Input from '../../components/form/input'
import Metafields from '../../components/form/partials/meta-fields'
import FormSections from '../../components/form/sections'
import Slug from '../../components/form/slug'
import Textarea from '../../components/form/textarea'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import NotificationContext from '../../components/notifications/context'
import SectionEditingStatus from '../../components/record/editing-status'
import RecordPublishButton from '../../components/record/publish-button'
import RecordTimestamps from '../../components/record/timestamps'
import RecordUnpublishButton from '../../components/record/unpublish-button'
import StatusBadge from '../../components/status/badge'
import pageFragment from '../../fragments/page'
import { requireAuthenticatedEditor } from '../../services/authentication'

const GET_PAGE_QUERY = gql`
  query GetPage($id: ID!) {
    page(id: $id) {
      ${pageFragment}
    }
  }
`

const UPDATE_PAGE_MUTATION = gql`
  mutation UpdatePageMutation($id: ID!, $titleDe: String, $titleEn: String, $slugDe: String, $slugEn: String, $teaserDe: String, $teaserEn: String, $coverImage: ID, $showCoverImage: Boolean, $metaTitleDe: String, $metaTitleEn: String, $metaDescriptionDe: String, $metaDescriptionEn: String, $keywordsDe: String, $keywordsEn: String, $publishDate: ISO8601DateTime ) {
    updatePage(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, slugDe: $slugDe, slugEn: $slugEn, teaserDe: $teaserDe, teaserEn: $teaserEn, coverImage: $coverImage, showCoverImage: $showCoverImage, metaTitleDe: $metaTitleDe, metaTitleEn: $metaTitleEn, metaDescriptionDe: $metaDescriptionDe, metaDescriptionEn: $metaDescriptionEn, keywordsDe: $keywordsDe, keywordsEn: $keywordsEn, publishDate: $publishDate }) {
      page {
        ${pageFragment}
      }
    }
  }
`
export default function PageForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_PAGE_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.page)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_PAGE_MUTATION, {
    onError: () => {
      addNotification({ title: 'Error updating page', message: 'The page could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.page?.titleDe} - Page`, [data])
  useTitle(title)

  if (error) {
    console.error(error)
    return <div>There was an error loading the page.</div>
  }

  const onSubmit = () => { }

  return (
    <Layout>
      <LayoutCompass
        label="page"
        title={title}
        backTo='/pages'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors} />
                <Fieldset>
                  <Input
                    label="Title - German"
                    name="titleDe"
                    register={methods.register}
                  />
                  <Input
                    label="Title- English"
                    name="titleEn"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Slug
                    label="Slug - German"
                    name="slugDe"
                    control={methods.control}
                  />
                  <Slug
                    label="Slug - English"
                    name="slugEn"
                    ccontrol={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <Textarea
                    label="Teaser - German"
                    name="teaserDe"
                    control={methods.control}
                  />
                  <Textarea
                    label="Teaser - English"
                    name="teaserEn"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Cover Image'
                    value={data?.page?.coverImage}
                    methods={methods}
                    name="coverImage.id"
                  />
                  <Boolean
                    label="Show Cover Image"
                    name="showCoverImage"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <FormSections
                    recordId={data?.page?.id}
                    sections={data?.page?.sections}
                  />
                </Fieldset>
                <Fieldset>
                  <DateInput
                    label="Publish Date"
                    name="publishDate"
                    control={methods.control}
                    datetime
                    help="Only add this if you want to add the page to the home hero."
                  />
                </Fieldset>
                <Metafields methods={methods} />
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <div className="flex justify-between items-center">
                <div className="font-bold">Status</div>
                <StatusBadge status={data?.page?.editingStatus} />
              </div>
              <RecordPublishButton
                record={data?.page}
                label="page"
                methods={methods}
              />
              <RecordUnpublishButton
                record={data?.page}
                label="page"
                methods={methods}
              />
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <RecordTimestamps
                record={data?.page}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
